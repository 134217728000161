import PdfViewer from 'awesome-pdf-viewer';
import BookmarkPanel from 'awesome-pdf-viewer/dist/bookmark/BookmarkPanel';
import Header from 'awesome-pdf-viewer/dist/layout/Header';
import LeftPanel from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import Main from 'awesome-pdf-viewer/dist/layout/Main';
import RightPanel from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import Toolbar from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import * as React from 'react';
import { PdfSource, ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import Pagination from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';

export interface ViewProps {
    url: string;
    onViewModeChange?(viewerMode: ViewerMode): void;
    leftPanel?: any;
}


enum BookmarkTab {
  Thumbnail = 1,
  Bookmarks = 2,
}

export interface ViewState {
}
enum BookmarkTab {
    Thumbnail = 1,
    Bookmarks = 2,
  }

export class Viewer extends React.Component<ViewProps, ViewState> {

    private _viewPanel: any;
    private _toolbar: any;
    private _bookmarkPanel: any;

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setReferences();
    }

    setReferences() {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentDidUpdate() {
        this.setReferences();
    }

    public render() {

        var pdfSource = PdfSource.createFromUrl(this.props.url);

        return (<PdfViewer>
            <Header>
                <Toolbar ref={(ref: any) => this._toolbar = ref} onViewModeChange={this.props.onViewModeChange} >
                    <Pagination />
                    <Zoom />
                </Toolbar>
            </Header>
            <Main>
                <LeftPanel>
                    {this.props.leftPanel}
                </LeftPanel>
                <ViewPanel ref={(ref: any) => this._viewPanel = ref}
                    pdfSource={pdfSource} >
                </ViewPanel>
                <RightPanel>
                    <BookmarkPanel ref={(ref: any) => { this._bookmarkPanel = ref; }}  defaultTab={BookmarkTab.Thumbnail}></BookmarkPanel>
                </RightPanel>
            </Main>
        </PdfViewer>);
    }
}
