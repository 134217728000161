import * as React from "react";
import { Shimmer } from "../../components/Common/Shimmer/Shimmer";
import {
  ClientType,
  SignatureStatus,
  SignatureType,
  DocumentStatus,
  Role,
  EngagementType,
  RedirectionSourcePage,
} from "src/core/common/Enums";
import { FormType, ITaxReturn } from "src/core/domain/models/ITaxReturn";
import { History } from "history";
import {
  ISignerModel,
  SignerStatus,
} from "src/core/domain/models/ISignerModel";
import { CompletedSummaryImage } from "./Parts/CompletedSummaryImage";
import { any } from "prop-types";
import { Row } from "react-bootstrap";
import { WelcomeMessage } from "./Parts/WelcomeMessage";
import { NextSignerAlert } from "./Parts/NextSignerAlert";
import {
  CompletedSummaryConstant,
  DownloadDocumentsConstants,
  DelegateeSignerConstants,
} from "../Common/Constants";
import { Welcome } from "../Summary/Main/Welcome";
import { PathConstants } from "../Common/Constants";
import { ISignedDocument } from "src/core/domain/models/manualsign/SignedDocument";
import { IAdditionalEsignDocument } from "src/core/domain/models/IAdditionalEsignDocument";
import { IDownloadableDocumentsViewModel } from "src/core/domain/viewModels/IDownloadableDocumentsViewModel";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { getDecodedClientId } from "../Helper/HelperFunction";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import {
  IDelegateeInfo,
  IDelegateeSignerDetails,
} from "../../core/domain/models/DelegateeModels";
import { AssignToDelegatee } from "../Delegatee/AssignToDelegatee";
import { logger } from "../../routes";
import { LogEventConstants } from "../Helper/Constants";
import IconUpdateDelegatee from "src/assets/images/icon-update-delegatee.svg";
import { IClientProcessViewModel } from "src/core/domain/viewModels/IClientProcessViewModel";

export interface CompletedWelcomePageProps {
  taxYear: number;
  param: any;
  clientName: string;
  hasShareholders: boolean;
  clientType: ClientType;
  taxReturn: any;
  sendSigningReminder: (clientId: string) => any;
  history: History;
  downloadSignedEFileDocument: (
    clientId: string,
    filename: string,
    callback?: () => void
  ) => any;
  sigedDocument: ISignedDocument[];
  downloadAllAdditionEsignDocuments: (
    clientId: string,
    filename: string
  ) => any;
  downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
  downloadEFileDocument: (
    clientId: string,
    filename: string,
    callback?: () => void
  ) => any;
  role: Role;
  signingRequiredForDocument: boolean;
  filingFormsExists: boolean;
  nextSignerExists: boolean;
  isPreviewMode: boolean;
  updateDelegateeSignerDetails: (
    clientId: string,
    delegatee: IDelegateeSignerDetails,
    taxyear: number,
    callback: () => void
  ) => void;
  isDelegateeAssigned: boolean;
  isSignCompleted: boolean;
  cancelDelegation: (
    clientId: string,
    remarks: string,
    taxYear: number,
    callback: () => void
  ) => void;
  resetLastVisitedSteps: (clientId: string, callback: () => void) => void;
  delegateeSigner: IDelegateeInfo;
  voucherExists: boolean;
  signingRequiredForSigner: boolean;
  clientProcessData: IClientProcessViewModel;
  getClientHubDomainURL: (
    id: string,
    callback: (clientHubDomainURL: string) => void
  ) => void;
}

interface CompletedWelcomePageState {
  showModal: boolean;
}

const pageTitle = "Completed Summary welcome";

export class CompletedWelcomePage extends React.Component<
  CompletedWelcomePageProps,
  CompletedWelcomePageState
> {
  constructor(props: CompletedWelcomePageProps) {
    super(props);

    this.state = {
      showModal: false,
    };

    logger.trackEvent(
      logger.buildEvent(`${LogEventConstants.Common.Open}  ${pageTitle}`, {
        count: 1,
        page: pageTitle,
        clientId: this.props.param.clientId,
      })
    );
  }

  viewDocumentDocument = () => {
    this.props.history.push(
      `${PathConstants.Download}${this.props.param.clientId}`
    );
  };

  getSignedEfileName = () => {
    const fileName = this.getFileNamePrefix();
    if (this.props.sigedDocument && this.props.sigedDocument.length === 1) {
      return fileName + "_Extensions_Signed Filing Forms.pdf";
    } else if (
      this.props.sigedDocument &&
      this.props.sigedDocument.length > 1
    ) {
      return fileName + "_Signed Document.zip";
    }
    return fileName + "_Extensions_Filing Forms.pdf";
  };

  getFileNamePrefix = () => {
    return (
      this.props.clientName +
      "_" +
      this.props.taxYear +
      "_" +
      this.getEngagementType(this.props.taxReturn.engagementType)
    );
  };

  getEngagementType = (engagementType: EngagementType) => {
    return EngagementType[EngagementType[engagementType]] ===
      EngagementType[EngagementType.E990]
      ? "990T"
      : engagementType.toString().replace("E", "");
  };

  getOtherSignedDocumentFileName = () => {
    return this.getFileNamePrefix() + "_Extensions_Other Signed Documents.zip";
  };

  openDelegationPopUp = () => {
    this.setState({ showModal: true });
    logger.trackEvent(
      logger.buildEvent(
        `${LogEventConstants.Common.Open} Assign To delegatee Modal`,
        {
          count: 1,
          page: "Assign To delegatee Modal",
          clientId: this.props.param?.clientId,
        }
      )
    );
  };

  closeDelegationPopUp = () => {
    this.setState({ showModal: false });
  };

  downloadSignedDocument = () => {
    const _self = this;

    const {
      taxReturn: {
        documentStatus,
        documentSettings: {
          documentSignatureSetting: { signatureFormSelectionType },
        },
      },
      filingFormsExists,
    } = this.props;

    const additionalEsignDocuments: IAdditionalEsignDocument[] =
      this.props.downloadableDocumentsViewModel &&
      this.props.downloadableDocumentsViewModel.additionalEsignDocuments;

    const efile =
      this.props.downloadableDocumentsViewModel &&
      this.props.downloadableDocumentsViewModel.downloadableDocuments.find(
        (x: any) => x.groupId === FormType.EFile
      );

    if (
      signatureFormSelectionType ===
      SignatureType.ESignWhenAllIncludedReturnsAllowed
    ) {
      if (efile && efile.documentId > 0 && filingFormsExists) {
        if (!this.props.isPreviewMode) {
          documentStatus.toString() ===
            DocumentStatus[DocumentStatus.USERSIGNED] ||
          documentStatus.toString() ===
            DocumentStatus[DocumentStatus.USERREVIEWED]
            ? this.props.downloadSignedEFileDocument(
                this.props.param.clientId,
                this.getSignedEfileName(),
                () => {
                  if (
                    additionalEsignDocuments &&
                    additionalEsignDocuments.length > 0
                  ) {
                    _self.props.downloadAllAdditionEsignDocuments(
                      _self.props.param.clientId,
                      _self.getOtherSignedDocumentFileName()
                    );
                  }
                }
              )
            : this.props.downloadEFileDocument(
                this.props.param.clientId,
                this.getSignedEfileName(),
                () => {
                  if (
                    additionalEsignDocuments &&
                    additionalEsignDocuments.length > 0
                  ) {
                    _self.props.downloadAllAdditionEsignDocuments(
                      _self.props.param.clientId,
                      _self.getOtherSignedDocumentFileName()
                    );
                  }
                }
              );
        } else {
          MarsNotifier.Warning(
            DownloadDocumentsConstants.DownloadNotAllowed,
            null
          );
          return;
        }
      }

      logger.trackEvent(
        logger.buildEvent(
          `${LogEventConstants.Common.CompletedSummary.DownloadSignedDocuments}  ${pageTitle}`,
          { count: 1, page: pageTitle, clientId: this.props.param.clientId }
        )
      );
    } else {
      this.props.history.push(
        `${PathConstants.ManualSign}${this.props.param.clientId}`
      );
    }
  };

  onMakeTaxPayments = () => {
    this.props.history.push(`${PathConstants.Pay}${this.props.param.clientId}`);
    logger.trackEvent(
      logger.buildEvent(
        `${LogEventConstants.Common.CompletedSummary.GoToPayment}  ${pageTitle}`,
        { count: 1, page: pageTitle, clientId: this.props.param.clientId }
      )
    );
  };

  printFiledReturn = () => {
    //routing logic foe print filed return
  };

  payTaxPrepFee = () => {
    //routing logic for pay TaxPrepareation Fee
  };

  ondistributeK1 = () => {
    this.props.history.push(
      `${PathConstants.DistributeK1}${this.props.param.clientId}`
    );
  };

  sendReminder = (clientId: string) => {
    if (this.props.role.toString() !== Role[Role.CPA].toString()) {
      this.props.sendSigningReminder(clientId);
    }
  };

  redirectToOneHub = () => {
    this.props.getClientHubDomainURL(
      this.props.param.clientId,
      (oneHubDomainURL: string) => {
        window.location.href = oneHubDomainURL;
      }
    );
  };

  public render() {
    const {
      signingRequiredForDocument,
      nextSignerExists,
      taxReturn: {
        documentSettings: {
          documentSignatureSetting: { signatureFormSelectionType },
        },
        relatedTaxReturnDelivered,
      },
    } = this.props;

    const voucherExists = this.props.voucherExists;
    let imgBtnDivClass = "col";

    let downloadButtonClass =
      (relatedTaxReturnDelivered || !voucherExists) &&
      (this.props.signingRequiredForSigner ||
        signatureFormSelectionType != SignatureType.ReviewOnly)
        ? "download-button-padding"
        : "";
    let signButtonClass =
      (relatedTaxReturnDelivered || !voucherExists) &&
      (this.props.signingRequiredForSigner ||
        signatureFormSelectionType != SignatureType.ReviewOnly)
        ? "sign-button-padding"
        : "";

    const returnUnSigned =
      this.props.taxReturn.signedDetails &&
      this.props.taxReturn.signedDetails.filter(
        (x: ISignerModel) => x.signerStatus === SignerStatus.NotSigned
      );

    let nextSigerName = this.props.taxReturn?.spouse?.name;

    let reminderAlert = CompletedSummaryConstant.SpouseReminderAlertMessage;

    const clientGuid = getDecodedClientId(this.props.param.clientId);

    const action = nextSignerExists ? " Signature" : " Review";
    let signedDetails: ISignerModel[] = this.props.taxReturn.signedDetails;
    let signatureFormSelection: any =
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType;
    let welcomeAlertMessage = "";

    if (
      returnUnSigned.length === 0 ||
      (this.props.taxReturn.signedDetails &&
        this.props.taxReturn.signedDetails.length < 2)
    ) {
      welcomeAlertMessage = CompletedSummaryConstant.CompletedMessage.replace(
        "<TAXYEAR>",
        this.props.taxYear.toString()
      );
    } else if (
      TaxpayerHelper.isFirstSignerLoggedIn(
        this.props.taxReturn,
        signedDetails,
        clientGuid
      )
    ) {
      if (
        signatureFormSelection == SignatureType.ManualSign &&
        signedDetails.filter((x) => x.signerStatus == SignerStatus.NotSigned)
          .length > 1
      ) {
        welcomeAlertMessage =
          CompletedSummaryConstant.SecondManualNosignAlertMessage.replace(
            "<SIGNERNAME>",
            nextSigerName
          );
      } else {
        welcomeAlertMessage =
          CompletedSummaryConstant.SecondSignerAlertMessage.replace(
            "<SIGNERNAME>",
            nextSigerName
          ) + action;
      }
    }
    return (
      <div
        id="complete_sum"
        data-test-auto="568B4554-8CFD-4965-9F71-084E1365E564"
      >
        {this.props.clientName && this.props.taxReturn.signedDetails ? (
          <React.Fragment>
            <div className="margin-top-20">
              <WelcomeMessage message={`${welcomeAlertMessage}`} />
            </div>

            {this.props.taxReturn.signedDetails &&
              this.props.taxReturn.signedDetails.length > 1 &&
              (this.props.taxReturn.signatureStatus ===
                SignatureStatus[SignatureStatus.AwaitingESign] ||
                this.props.taxReturn.signatureStatus ===
                  SignatureStatus[SignatureStatus.PartiallySigned]) &&
              returnUnSigned.length > 0 &&
              signingRequiredForDocument &&
              TaxpayerHelper.isFirstSignerLoggedIn(
                this.props.taxReturn,
                this.props.taxReturn.signedDetails,
                clientGuid
              ) &&
              TaxpayerHelper.ifDelegateeExistsAndSigned(
                this.props.isDelegateeAssigned,
                this.props.isSignCompleted
              ) && (
                <div
                  className="margin-top-20"
                  data-test-auto="A0809C33-629A-476A-8192-50B496BFFD45"
                >
                  <NextSignerAlert
                    alertMessage={reminderAlert}
                    icon={CompletedSummaryConstant.Images.InfoCircle}
                    nextSignerName={nextSigerName}
                    onclick={() => {
                      this.sendReminder(this.props.param.clientId);
                    }}
                    clientId={this.props.param.clientId}
                  />
                </div>
              )}
            <div
              className="welcome-intro"
              data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24"
            >
              {CompletedSummaryConstant.InstructionText}
            </div>
          </React.Fragment>
        ) : (
          <Shimmer height={200} />
        )}

        {this.props.clientName && this.props.taxReturn.signedDetails ? (
          <>
            <div className="step-img-btn margin-top-70">
              <div
                className={`${downloadButtonClass} ${imgBtnDivClass}`}
                data-test-auto="6C6403FF-9D2C-41AC-ADFE-1209675FE325"
              >
                <CompletedSummaryConstant.Images.ViewDocument
                  dataAutoTest="37A6F6EC-509E-4551-B775-24ECA80C0061"
                  className="button---download-documents"
                  onClick={this.viewDocumentDocument}
                />
                <div
                  className="img-caption"
                  onClick={this.viewDocumentDocument}
                >
                  {CompletedSummaryConstant.ImageCaption.ViewDownloadDocument}
                </div>
              </div>

              {signingRequiredForDocument ||
              signatureFormSelectionType === SignatureType.ManualSign ? (
                <div
                  className={`${imgBtnDivClass} ${signButtonClass}`}
                  data-test-auto="5CB48E27-DE28-4A04-B3ED-ACBFC4AE423E"
                >
                  <CompletedSummaryConstant.Images.ViewDownloadSignedDocument
                    dataAutoTest="E54CED95-F4D5-40DE-8EEE-0C3AF0A4A77E"
                    className="button---signed-documents"
                    onClick={this.downloadSignedDocument}
                  />
                  <div
                    className="img-caption"
                    onClick={this.downloadSignedDocument}
                  >
                    {
                      CompletedSummaryConstant.ImageCaption
                        .ViewDownloadSignedDocument
                    }
                  </div>
                </div>
              ) : undefined}
              {!relatedTaxReturnDelivered && voucherExists && (
                <div
                  className={imgBtnDivClass}
                  data-test-auto="9EC15877-B832-4107-AC19-47E29D43818A"
                >
                  <CompletedSummaryConstant.Images.MakeTaxPayment
                    dataAutoTest="9A7A3F04-B153-4BB1-9005-A8DB5EA558E8"
                    className="button---tax-payments"
                    onClick={this.onMakeTaxPayments}
                  />
                  <div className="img-caption" onClick={this.onMakeTaxPayments}>
                    {CompletedSummaryConstant.ImageCaption.MakeTaxPayment}
                  </div>
                </div>
              )}

              {/* <div className="row complete-wizard-welcome-intro">
                                 <div className="col-lg-3 col-md-6  margin-top-20 margin-left-5">
                                        <CompletedSummaryImage
                                            icon={CompletedSummaryConstant.Images.PrintFiledReturn}
                                            imageCaption={CompletedSummaryConstant.ImageCaption.PrintFiledReturn}
                                            onClick={this.printFiledReturn}
                                        />
                                    </div>

                                    <div
                                        className="col-lg-3 col-md-6  margin-top-20 margin-left-5"
                                        data-test-auto="3000C8D4-7541-4B32-89AE-AA3B6EDF4CBC">
                                        <CompletedSummaryImage
                                            icon={CompletedSummaryConstant.Images.PayTaxPrepFee}
                                            imageCaption={CompletedSummaryConstant.ImageCaption.PayTaxPrepFee}
                                            onClick={this.payTaxPrepFee}
                                        />
                                    </div></div>*/}

              {this.props.isDelegateeAssigned &&
                !this.props.isSignCompleted &&
                !this.props.isPreviewMode && (
                  <div
                    className={imgBtnDivClass}
                    onClick={this.openDelegationPopUp}
                    data-test-auto="6C6403FF-9D2C-41AC-ADFE-1209675FE325"
                  >
                    <div className="update-delegatee-icon">
                      <img src={IconUpdateDelegatee} />
                    </div>
                    <div
                      className="img-caption"
                      onClick={this.openDelegationPopUp}
                    >
                      {CompletedSummaryConstant.ImageCaption.UpdateDelegatee}
                    </div>
                  </div>
                )}

              <AssignToDelegatee
                show={this.state.showModal}
                onCancel={this.closeDelegationPopUp}
                param={this.props.param}
                history={this.props.history}
                taxReturn={this.props.taxReturn}
                updateDelegateeSignerDetails={
                  this.props.updateDelegateeSignerDetails
                }
                isDelegateeAssigned={this.props.isDelegateeAssigned}
                cancelDelegation={this.props.cancelDelegation}
                resetLastVisitedSteps={this.props.resetLastVisitedSteps}
                successMsg={
                  DelegateeSignerConstants.SuccessMessage.UpdateDelegateeSuccess
                }
                delegateeSigner={this.props.delegateeSigner}
                isPreviewMode={this.props.isPreviewMode}
              />
            </div>
          </>
        ) : (
          <Shimmer height={500} />
        )}

        {this.props.clientProcessData.isAccessedFromOneHub && (
          <button
            className={`${
              this.props.clientProcessData.redirectionSourcePage ===
              RedirectionSourcePage.Signcompleted
                ? "returnToHomeBtn"
                : "completebtn"
            } flrt`}
            onClick={() => this.redirectToOneHub()}
          >
            {this.props.clientProcessData.redirectionSourcePage ===
            RedirectionSourcePage.Signcompleted
              ? "Return To Home"
              : "Complete"}
          </button>
        )}
      </div>
    );
  }
}
